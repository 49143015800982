import { useEffect, useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import axios from 'axios'
import { createApi } from 'unsplash-js';

import "react-image-lightbox/style.css";
import './index.css'

const API_URL = 'https://ala.freshandfunky.co'
// const API_URL = 'http://localhost:4000'

const PEXELS_API_KEY = 'OSCvPMha37JH3k7KcmPqG2OOozAP0iiQmQDG2rno0sC54ZrFwaBrS7v2'
const PEXELS_API_URL = (query, page = 1) => `https://api.pexels.com/v1/search?query=${query}&size=small&per_page=30&page=${page}`

const UNSPLASH_API_KEY = "tp-xP00iiTPZVgX1WyX_p7fppLrrjE5g2-SvGPzbM5g"
// const UNSPLASH_API_SECRET = "K2C6boR21nUe9YUacYaRdnj0D5EQdnvdfkQAQzpVa9E"
// const UNSPLASH_API_URL = "https://api.unsplash.com/search/photos?page=1&query=office"


export default function App() {
  const [index, setIndex] = useState(-1);
  const [images, setImages] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [processed, setProcessed] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);


const unsplash = createApi({
  accessKey: UNSPLASH_API_KEY,
});



const getImgData = (ev) => {
  const files = ev.target.files;
  const imagesData = []

  for(let i=0; i<files.length; i++) {

    const file = files[i]
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = function (e) {
      //Initiate the JavaScript Image object.
      const image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        const height = this.height;
        const width = this.width;
        imagesData.push({ height, width, src: image.src, id: Date.now() })

        if(i === files.length - 1) {
          setImages(imagesData)
        }
        return true;
      };
      };
  }
}

const processedImagesHandler = async() => {
  if(processed) {
    setImages([])
    setProcessed(false)
    return;
  }

  setProcessing(true)
  axios.post(API_URL + '/process', { images })
  .then(res => {
    setImages(res.data)
    setProcessing(false)
    setProcessed(true)
  })
  .catch(err =>  {
    console.log(err)
    setProcessing(false)
  })
}

const downloadImagesHandler = async() => {
  return axios({
    url: API_URL + '/download',
    method: 'GET',
    responseType: 'blob',
  })
    .then(response => {
      const href = window.URL.createObjectURL(response.data);

      const anchorElement = document.createElement('a');

      anchorElement.href = href;
      anchorElement.download = 'processedImages.zip';

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    })
    .catch(error => {
      console.log('error: ', error);
    });
}

const searchUnsplash = async () => {
  return unsplash.search.getPhotos({ query: search, page: currentPage, perPage: 30 })
  .then(({response}) => {
    const pictures = response.results.map(({height, width, id, urls }) => ({height, width, id, src: urls.regular }))
    return pictures;
  })
  .catch(err => {
    console.log(err)
    return []
  })
}

const searchPexels = async () => {
  return axios.get(PEXELS_API_URL(search, currentPage), { headers: {Authorization: PEXELS_API_KEY} })
  .then(resp => {
    const pictures = resp.data.photos.map(({height, width, id, src }) => ({height, width, id, src: src.large }))
    return pictures
  })
  .catch(err => {
    console.log(err)
    return []
  })
}

const searchHandler = (initial) => {
  if(!search) return;

  if(initial) setPage(1);

  setTimeout(async () => {
    setLoading(true)
    setImages([])
    const res1 = await searchUnsplash()
    const res2 = await searchPexels()
    setImages(res1.concat(res2))
    setLoading(false)
  }, 500);
}

const previousHandler = () => setPage(currentPage - 1)
const nextHandler = () => setPage(currentPage + 1)

useEffect(() => {
  if(currentPage >= 1) {
    searchHandler()
  }
}, [currentPage])

  return (
    <div className='wrapper'>
       <div className='searchWrapper'>
       {images.length === 0 &&
        <>
        <div className='fileInput'>
          <label htmlFor="fileInput">Choose images</label>
          <input type='file' id="fileInput"  accept="image/*" onChange={getImgData} multiple />
          </div>
          <h1>OR</h1>
        </>

      }
      <div className="searchBox">    
      <input 
      onChange={(e) => setSearch(e.target.value)}
      value={search}
      className="searchInput"
      type="text" name="" 
      placeholder="Search" />
      <button className="searchButton" href="#" onClick={() => searchHandler(true)}>
          <i className="material-icons">
              search
          </i>
      </button>
  </div>
  </div>

  {loading && <div className='loaderWrapper'><div class="lds-circle"><div></div></div></div>}

      { images.length !== 0 && <>
      <div className='buttonWrapper'>
        <button className="button-41" onClick={processedImagesHandler} disabled={processing}>
          {processing ? 'Processing...' : processed ? 'Restart' : 'Process Images'}
        </button> 
        <br/>
       {processed && <button className="button-41" onClick={downloadImagesHandler} disabled={processing}>
          Download All
        </button>
        }
      </div>
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      />
      {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.src}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.src}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.src}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
      </> }

      {images.length !== 0 && <div className='pagination'>
        {currentPage > 1 && <button onClick={previousHandler} className='button-45'>Previous</button>}
        <button onClick={nextHandler} className='button-45'>Next</button>
      </div>}
    </div>
  );
}